import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { GameRoutingModule } from './game-routing.module';

import { SplashViewComponent } from './views/splash-view/splash-view.component';
import { QuestionsViewComponent } from './views/questions-view/questions-view.component';
import { ResultsViewComponent } from './views/results-view/results-view.component';
import { AnswersComponent } from './views/questions-view/answers/answers.component';
import { StatusComponent } from './views/questions-view/status/status.component';
import { PrimaryPlayertypeComponent } from './views/results-view/primary-playertype/primary-playertype.component';
import { SecondaryPlayertypeComponent } from './views/results-view/secondary-playertype/secondary-playertype.component';
import { InactivePlayertypeComponent } from './views/results-view/inactive-playertype/inactive-playertype.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    GameRoutingModule
  ],
  declarations: [
    SplashViewComponent,
    QuestionsViewComponent,
    ResultsViewComponent,
    AnswersComponent,
    StatusComponent,
    PrimaryPlayertypeComponent,
    SecondaryPlayertypeComponent,
    InactivePlayertypeComponent
  ]
})
export class GameModule { }
