import { Component, OnInit } from "@angular/core";

import { Results } from "../../types/results";
import { GameStateService } from "../../services/game-state.service";

// import * as AWS from "aws-sdk";
// import { v4 as uuid } from "uuid";

// import { Config } from "../../../../config";

@Component({
  selector: "app-results-view",
  templateUrl: "./results-view.component.html",
  styleUrls: ["./results-view.component.scss"],
})
export class ResultsViewComponent implements OnInit {
  private playerTypesCount: Object;
  private playerType: string;
  private secondaryPlayerType: string;
  private sortable: Array<any> = [];
  private inactiveTypes: Array<string>;
  public results: Results;
  private ddb: any;

  constructor(private gameStateService: GameStateService) {}

  ngOnInit() {
    this.playerTypesCount = this.gameStateService.getPlayerTypesCount();
    // this.saveToDb(this.playerTypesCount);
    this.evaluatePlayerType(this.playerTypesCount);
  }

  private evaluatePlayerType(playerTypesCount) {
    // Make an array: [['playertype', 'score'], ...]
    // We want to work with arrays here since it is probably not a good idea to assume the order of an object.
    for (let playerType in playerTypesCount) {
      this.sortable.push([playerType, playerTypesCount[playerType]]);
    }

    // Sort the array by highest score
    this.sortable.sort((a, b) => {
      return b[1] - a[1];
    });

    this.playerType = this.sortable[0][0];
    this.secondaryPlayerType = this.sortable[1][0];

    this.results = {
      playerType: this.sortable[0][0],
      secondaryPlayerType: this.sortable[1][0],
      inactive: this.removeActive(this.sortable),
    };
  }

  private removeActive(sortedResults) {
    // Remove the first two elements of the sorted array (playerType and secondaryPlayerType).
    sortedResults.splice(0, 2);
    let cleaned = [];

    // Clean it up and return only an array of playertype strings.
    for (let type in sortedResults) {
      cleaned.push(sortedResults[type][0]);
    }

    return cleaned;
  }

  // private saveToDb(data) {
  //   AWS.config.update({
  //     region: Config.awsRegion,
  //     accessKeyId: Config.awsAccessKeyId,
  //     secretAccessKey: Config.awsSecretAccessKey,
  //   });

  //   let docClient = new AWS.DynamoDB.DocumentClient();
  //   let table = "PlayerTypeData";

  //   let params = {
  //     TableName: table,
  //     Item: {
  //       id: uuid(),
  //       result: data,
  //       source: Config.dataSource,
  //     },
  //   };

  //   docClient.put(params, (err, data) => {
  //     if (err) {
  //       console.error(
  //         "Unable to add item. Error JSON:",
  //         JSON.stringify(err, null, 2)
  //       );
  //     } else {
  //       console.log("Added item:", JSON.stringify(data, null, 2));
  //     }
  //   });
  // }
}
