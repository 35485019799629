import { Component, OnInit, Input } from "@angular/core";

import { DataService } from "../../../services/data.service";
import { GameStateService } from "../../../services/game-state.service";

@Component({
  selector: "app-secondary-playertype",
  templateUrl: "./secondary-playertype.component.html",
  styleUrls: ["./secondary-playertype.component.scss"],
})
export class SecondaryPlayertypeComponent implements OnInit {
  @Input() result: string;

  public playerTypeData: any;
  public resultTitle: string;
  public resultHeadline: string;

  constructor(
    private dataService: DataService,
    private gameStateService: GameStateService
  ) {}

  async ngOnInit() {
    this.playerTypeData = this.gameStateService.getPlayerType(this.result);
    this.resultTitle = await this.dataService.getString("secondaryResultTitle");
    this.resultHeadline = await this.dataService.getString(
      "secondaryResultHeadline"
    );
  }
}
