import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

import { TranslateService } from "@ngx-translate/core";
import { DataService } from "../services/data.service";

@Injectable()
export class GameStateService {
  private questionSet;
  private playerTypeSet;
  private currentQuestionIndex: number;
  private currentQuestionNumber: number;

  private _gameState = {
    language: "de",
    currentQuestion: 0,
    totalNumberOfQuestions: 0,
    playerTypesCount: {
      player: 0,
      philanthrop: 0,
      achiever: 0,
      disruptor: 0,
      freeSpirit: 0,
      socialiser: 0,
    },
  };

  public gameState: BehaviorSubject<{
    currentQuestion: number;
    totalNumberOfQuestions: number;
  }>;

  constructor(
    private dataService: DataService,
    private translateService: TranslateService
  ) {
    this.gameState = new BehaviorSubject(this._gameState);
  }

  public setLanguage(languageCode: string) {
    this._gameState.language = languageCode;

    return new Promise<void>((resolve) => {
      switch (this._gameState.language) {
        case "en": {
          this.translateService.use("en");
          resolve();
          break;
        }
        case "de": {
          this.translateService.use("de");
          resolve();
          break;
        }
      }
    });
  }

  public async startGame() {
    return new Promise<void>(async (resolve) => {
      await this.dataService.setLanguage(this._gameState.language);
      this.questionSet = await this.dataService.getAllQuestions();
      this.playerTypeSet = await this.dataService.getAllPlayerTypes();
      this.currentQuestionIndex = 0;
      this.updateGameState();

      resolve();
    });
  }

  public getCurrentQuestion() {
    let currentQuestion = null;
    if (
      this.questionSet &&
      this.currentQuestionIndex < this.questionSet.length
    ) {
      currentQuestion = this.questionSet[this.currentQuestionIndex];
    }

    return currentQuestion;
  }

  public hasNextQuestion() {
    return this.currentQuestionIndex + 1 < this.questionSet.length;
  }

  public getNextQuestion() {
    let nextQuestion = null;

    if (this.hasNextQuestion()) {
      nextQuestion = this.questionSet[++this.currentQuestionIndex];
    }

    this.updateGameState();

    return nextQuestion;
  }

  public saveAnswers(givenAnswers) {
    for (let answer in givenAnswers) {
      givenAnswers[answer] === true
        ? ++this._gameState.playerTypesCount[answer]
        : null;
    }

    this.gameState.next(this._gameState);
  }

  public getAllPlayerTypes() {
    return this.playerTypeSet;
  }

  public getPlayerType(type: string) {
    return this.playerTypeSet[type];
  }

  public getPlayerTypesCount() {
    return this._gameState.playerTypesCount;
  }

  public getTotalNumberOfQuestions() {
    return this._gameState.totalNumberOfQuestions;
  }

  public getCurrentQuestionNumber() {
    return this._gameState.currentQuestion;
  }

  private updateGameState() {
    this._gameState.currentQuestion = this.currentQuestionIndex + 1;
    this._gameState.totalNumberOfQuestions = this.questionSet.length;
  }
}
