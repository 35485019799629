<section class="question">
  <h1 class="question-text">{{ question.question }}</h1>
</section>

<section class="options">
  <ul>
    <li *ngFor="let option of question.options; let index = index">
        <input class="answer-checkbox"
               name="{{option.playerType}}"
               type="checkbox"
               value="{{option.playerType}}"
               id="{{option.playerType}}"
               (change)="countTrueItems()"
               [(ngModel)]="model[option.playerType]"
               [disabled]="maxChecked && !model[option.playerType]" />
        <label for="{{option.playerType}}">
          {{option.text}}
        </label>
    </li>
  </ul>

  <div class="bottom-button-container in-flow">
      <div class="bottom-button-content">
        <button class="options-submit"
                (click)="confirm(model)"
                [disabled]="!maxChecked">
          {{ buttonLabel }}
        </button>
      </div>
  </div>

</section>
