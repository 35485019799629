import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SplashViewComponent } from './views/splash-view/splash-view.component';
import { QuestionsViewComponent } from './views/questions-view/questions-view.component';
import { ResultsViewComponent } from './views/results-view/results-view.component';

const routes: Routes = [
  {
    path: 'game/splash',
    component: SplashViewComponent
  },
  {
    path: 'game/question',
    component: QuestionsViewComponent
  },
  {
    path: 'game/results',
    component: ResultsViewComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GameRoutingModule { }
