import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GameStateService } from '../../services/game-state.service';

@Component({
  selector: 'app-splash-view',
  templateUrl: './splash-view.component.html',
  styleUrls: ['./splash-view.component.scss']
})
export class SplashViewComponent {

  constructor(
    private gameStateService: GameStateService,
    private router: Router
  ) { }

  public async startGame(languageCode) {
    this.gameStateService.setLanguage(languageCode);
    await this.gameStateService.startGame();
    this.router.navigate(['/game/question']);
  }
}
