import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { Question } from "../../types/question";
import { GameStateService } from "../../services/game-state.service";

@Component({
  selector: "app-questions-view",
  templateUrl: "./questions-view.component.html",
  styleUrls: ["./questions-view.component.scss"],
})
export class QuestionsViewComponent implements OnInit {
  public question: Question;

  constructor(
    private gameStateService: GameStateService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.question = this.gameStateService.getCurrentQuestion();
    !this.question ? this.router.navigate(["/"]) : null;
  }

  public nextQuestion() {
    this.gameStateService.hasNextQuestion()
      ? (this.question = this.gameStateService.getNextQuestion())
      : this.router.navigate(["/game/results"]);

    window.scrollTo(0, 0);
  }

  public updateCurrentQuestion() {
    // TODO: implement
  }
}
