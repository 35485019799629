import { Component, OnInit } from "@angular/core";

import { GameStateService } from "../../../services/game-state.service";

@Component({
  selector: "app-status",
  templateUrl: "./status.component.html",
  styleUrls: ["./status.component.scss"],
})
export class StatusComponent implements OnInit {
  public status: any;

  constructor(private gameStateService: GameStateService) {}

  ngOnInit() {
    this.gameStateService.gameState.subscribe((values) => {
      this.status = values;
    });
  }
}
