import { Component, OnInit, Input } from "@angular/core";

import { PlayerTypes } from "../../../data/playertypes";
import { GameStateService } from "../../../services/game-state.service";

@Component({
  selector: "app-inactive-playertype",
  templateUrl: "./inactive-playertype.component.html",
  styleUrls: ["./inactive-playertype.component.scss"],
})
export class InactivePlayertypeComponent implements OnInit {
  @Input() result: Array<string>;

  public inactiveTypeData: Object;
  public JSObject: any = Object;

  constructor(private gameStateService: GameStateService) {}

  ngOnInit() {
    this.inactiveTypeData = Object.keys(
      this.gameStateService.getAllPlayerTypes()
    )
      .filter((key) => this.result.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.gameStateService.getPlayerType(key);
        return obj;
      }, {});
  }
}
