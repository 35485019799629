<div class="logo-wrapper">
  <img src="assets/logo.svg" class="logo" />
</div>

<div class="bottom-button-container">
    <div class="bottom-button-content">
        <button (click)="startGame('en')">English</button>
        <button (click)="startGame('de')">Deutsch</button>
        <!-- <button (click)="startGame()">Los geht's!</button> -->
    </div>
</div>
