import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { Question } from "../../../types/question";

import { DataService } from "../../../services/data.service";
import { GameStateService } from "../../../services/game-state.service";

@Component({
  selector: "app-answers",
  templateUrl: "./answers.component.html",
  styleUrls: ["./answers.component.scss"],
})
export class AnswersComponent implements OnInit {
  @Output() questionConfirmed: EventEmitter<any> = new EventEmitter();
  @Input() question: Question;

  public checked: boolean[] = [];
  public model: Object = {
    player: false,
    philanthrop: false,
    achiever: false,
    disruptor: false,
    freeSpirit: false,
    socialiser: false,
  };
  public maxChecked: boolean = false;
  public buttonLabel: string;

  constructor(
    public dataService: DataService,
    public gameStateService: GameStateService
  ) {}

  async ngOnInit() {
    this.buttonLabel = await this.dataService.getString("buttonLabel");
  }

  private countTrueItems() {
    let result = 0;
    for (let element in this.model) {
      this.model[element] === true ? ++result : null;
    }

    result >= 2 ? (this.maxChecked = true) : (this.maxChecked = false);
  }

  private reInitializeModel() {
    this.model = {
      player: false,
      philanthrop: false,
      achiever: false,
      disruptor: false,
      freeSpirit: false,
      socialiser: false,
    };
    this.maxChecked = false;
  }

  public confirm(givenAnswers) {
    this.gameStateService.saveAnswers(givenAnswers);
    this.reInitializeModel();
    this.questionConfirmed.emit();
  }
}
