import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Questions } from '../data/questions';
import { Question } from '../types/question';

@Injectable()
export class DataService {
  constructor(private translateService: TranslateService) { }

  public setLanguage(languageCode: string) {
    return new Promise<void>(resolve => {
      switch (languageCode) {
        case 'en': {
          this.translateService.use('en');
          resolve();
          break;
        }
        case 'de': {
          this.translateService.use('de');
          resolve();
          break;
        }
      }
    });
  }

  public getAllQuestions() {
    return new Promise<string>(resolve => {
      this.translateService.get('questionSet').subscribe((res: string) => {
        resolve(res);
      });
    });
  }

  public getAllPlayerTypes() {
    return new Promise<string>(resolve => {
      this.translateService.get('playerTypes').subscribe((res: string) => {
        resolve(res);
      });
    });
  }

  public getString(name: string) {
    return new Promise<string>(resolve => {
      this.translateService.get('strings').subscribe((res: string) => {
        resolve(res[name]);
      });
    });
  }
}
